import api from ".";

export const getCallerip = (params) => {
    return api.get('/callerip', {
        params: params
    });
}
export const deleteCallerip = (id) => {
    return api.delete(`/callerip/${id}`);
}
export const postCallerip = (data) => {
    return api.post('/callerip', data);
}
export default {};