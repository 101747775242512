import api from ".";

export const getCallerList = (params) => {
    return api.get('/caller', {
        params: params
    })
}
export const getCallerAnalyse = (params = {}) => {
    if (!(params instanceof Object)) return Promise.reject('参数错误');
    let params_ = {
        cycle: params.cycle || 'day',
        section: params.section || 7,
    }
    for (let key in params) if (params[key] !== void 0) params_[key] = params[key];
    return api.post('/analyse', params_);
}
export default {};
