<template>
  <el-row :gutter="20">
    <el-col
      :md="12"
      :span="24"
    >
      <h3 class="title">商品信息</h3>
      <div class="text-info">
        <el-form :model="info">
          <el-form-item
            label="产品属类"
            prop="type1"
          >
            <el-radio-group
              v-model="info.type1"
              @change="productChangeType1"
            >
              <el-radio value="toner">Toner</el-radio>
              <el-radio value="spare-parts">Spare Parts</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="产品类型"
            prop="type2"
          >
            <el-select
              v-model="info.type2"
              placeholder="Select"
              size="large"
              style="width: 240px"
            >
              <el-option
                v-for="item in type2Options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="品牌"
            prop="brand"
          >
            <el-select
              v-model="info.brand"
              placeholder="Select"
              size="large"
              style="width: 240px"
            >
              <el-option
                label="Konica Minolta"
                value="Konica Minolta"
              />
              <el-option
                label="Ricoh"
                value="Ricoh"
              />
              <el-option
                label="Canon"
                value="Canon"
              />
              <el-option
                label="Xerox"
                value="Xerox"
              />
              <el-option
                label="Kyocera"
                value="Kyocera"
              />
              <el-option
                label="Sharp"
                value="Sharp"
              />
              <el-option
                label="Toshiba"
                value="Toshiba"
              />
              <el-option
                :disabled="info.type1 != 'toner'"
                label="HP"
                value="HP"
              />
              <el-option
                :disabled="info.type1 != 'toner'"
                label="Brother"
                value="Brother"
              />
              <el-option
                :disabled="info.type1 != 'toner'"
                label="Samsung"
                value="Samsung"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="主题"
            prop="theme"
          >
            <el-input
              v-model="info.theme"
              style="width: 300px"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-table
          border
          :data="detailList"
          style="width: 100%"
        >
          <el-table-column
            label="显示字段"
            prop="display"
          >
            <template #default="{ row }">
              <el-input
                :readonly="row.required"
                v-model="row.display"
                placeholder="Place Field"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="显示的值"
            prop="value"
          >
            <template #default="{ row }">
              <el-input
                v-model="row.value"
                placeholder="Place value"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="200"
            className="operate"
          >
            <template #default="scope">
              <el-button
                :disabled="scope.row.required"
                @click="deleteRow(scope.$index)"
                >删除</el-button
              >
              <div :class="{sort: true, disabled: scope.$index == 0}" @click="handleSort(scope, 'top')">
                <el-icon><Top /></el-icon>
              </div>
              <div :class="{sort: true, disabled: scope.$index == detailList.length - 1}" @click="handleSort(scope, 'bottom')">
                <el-icon><Bottom /></el-icon>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-button
          style="width: 100%"
          @click="addItem"
          >添加</el-button
        >
      </div>
    </el-col>
    <el-col
      :md="12"
      :span="24"
    >
      <h3 class="title">图片信息</h3>
      <div class="image-info">
        <el-upload
          v-model:file-list="coverList"
          method="post"
          accept="image/jpeg,image/png,image/gif"
          auto-upload
          action="/docu/upload"
          list-type="picture-card"
          :on-preview="handlePrview"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
          :before-upload="handleBeforeUpload"
          :on-error="handleError"
        >
          <el-icon><Plus /></el-icon>
        </el-upload>
      </div>
    </el-col>
  </el-row>
  <div class="header-btn">
    <el-button
      @click="saveBtn"
      type="success"
      >上架</el-button
    >
    <el-button
     @click="cacheBtn"
      type="primary"
      >暂存</el-button
    >
  </div>
  <el-dialog v-model="dialogVisible" append-to-body>
    <div class="dialog-image">
      <img w-full :src="dialogImageUrl" fit="cover" alt="Preview Image" />
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElRadioGroup,
  ElRadio,
  ElSelect,
  ElOption,
  ElTable,
  ElTableColumn,
  ElButton,
  ElMessage,
  ElUpload,
  ElIcon,
  ElLoading,
  ElDialog
} from "element-plus";
import { Plus, Top, Bottom } from "@element-plus/icons-vue";
import { postProduct } from "../../api/produt";
const info = ref({
  name: "",
  theme: "",
  type1: "",
  type2: "",
  brand: "",
  typeName: "",
  salesNumber: 1,
});
const dialogVisible = ref(false);
const dialogImageUrl = ref('');
const coverList = ref([]);
const detailList = ref([]);
const tonerOptions = [
  {
    label: "Toner Cartridge",
    value: "Toner Cartridge",
  },
  {
    label: "Toner Powder",
    value: "Toner Powder",
  },
];
const sparePartsOptions = [
  {
    label: "Drum Units",
    value: "Drum Units",
  },
  {
    label: "Developer Units",
    value: "Developer Units",
  },
  {
    label: "Fuser Units",
    value: "Fuser Units",
  },
  {
    label: "OPC Drum",
    value: "OPC Drum",
  },
  {
    label: "Cleaning Blade",
    value: "Fuser Films",
  },
  {
    label: "Other Parts",
    value: "Other Parts",
  }
];
const type2Options = ref(tonerOptions);
const productChangeType1 = (type) => {
  info.value.type2 = "";
  if (type === "toner") {
    type2Options.value = tonerOptions;
    detailList.value = detailList.value.filter(
      (item) => item.display !== "OPC Drum"
    );
    detailList.value.push(
      {
        sort: 4,
        display: "Toner Load",
        value: "",
        required: true,
      },
      {
        sort: 7,
        display: "Toner Powder",
        value: " lmported Toner Powder",
        required: true,
      }
    );
    detailList.value.find((item) => item.display === "Feature").value =
      "Compatible";
  } else {
    type2Options.value = sparePartsOptions;
    detailList.value = detailList.value.filter(
      (item) => item.display !== "Toner Load" && item.display !== "Toner Powder"
    );
    detailList.value.push({
      sort: 5,
      display: "OPC Drum",
      value: "",
    });
    detailList.value.find((item) => item.display === "Feature").value =
      "Remanufactured";
  }
  detailList.value.sort((a, b) => a.sort - b.sort);
};
const deleteRow = (index) => {
  detailList.value.splice(index, 1);
  ElMessage.success("删除成功");
};
const addItem = () => {
  detailList.value.push({
    display: "",
    value: "",
  });
  ElMessage.success("添加成功");
};
const handleBeforeUpload = (uploadFile) => {
  const type = uploadFile.type;
  if (type !== "image/jpeg" && type !== "image/png" && type !== "image/gif") {
    ElMessage.warning("请选择正确的文件类型, 当前的类型为" + type);
    return false;
  }
  const size = (uploadFile.size / 1024 / 1024).toFixed(2);
  if (size > 5) {
    ElMessage.warning("当前的文件大小为" + size + "MB, 最大不超过 5MB");
    return false;
  }
  const img = new Image();
  let _URL = window.URL || window.webkitURL;
  return new Promise((resolve, reject) => {
    img.onload = () => {
      if (img.width / img.height >= 0.90 && img.width / img.height <= 1.5) {
        resolve();
      } else {
        ElMessage.info(
          `当前的图片宽高比为 ${(img.width / img.height).toFixed(
            2
          )}, 宽高比的范围是 0.950 ~ 1.10`
        );
        reject();
      }
    };
    img.src = _URL.createObjectURL(uploadFile);
  });
};
const handleRemove = () => {
  ElMessage.success("图片删除成功");
};
const handleSuccess = (file) => {
  if (file.code == 200) {
    ElMessage.success("图片上传成功");
  } else {
    ElMessage.error("图片上传失败");
    coverList.value.pop();
  }
};
const handleError = (err) => {
  ElMessage.error("图片上传失败", err);
};
const handleSort = (data, direction) => {
  let currentRow = detailList.value.splice(data.$index, 1);
  if (direction == 'top') {
    detailList.value.splice(data.$index - 1, 0, ...currentRow)
  } else {
    detailList.value.splice(data.$index + 1, 0, ...currentRow)
  }
}
const saveBtn = () => {
  if (coverList.value.length === 0) {
    ElMessage.warning("至少上传一张图片"); 
    return false;
  }
  detailList.value = detailList.value.filter((item) => item.display);
  if (
    detailList.value.every((item) => item.value.trim()) &&
    info.value.brand &&
    info.value.theme &&
    info.value.type2 &&
    coverList.value.length
  ) {
    const loadinginstarnface = ElLoading.service({
      background: 'rgba(223, 223, 223, .7)'
    });
    info.value.salesNumber = 1;
    info.value.coverList = coverList.value.map((item) => item.response.url);
    info.value.detail = detailList.value.map((item) => ({
      display: item.display,
      value: item.value,
    }));
    postProduct(info.value)
      .then((res) => {
        if (res.code === 200) {
          ElMessage.success("添加成功");
          init();
        } else {
          ElMessage.error("添加失败");
        }
        loadinginstarnface.close();
      }).catch(() => {
        ElMessage.error("服务错误");
        loadinginstarnface.close();
      });
  } else {
    ElMessage.warning("请填写完所有信息");
  }
  info.value.coverList = coverList.value.map((item) => item.response.url);
  info.value.detail = detailList.value;
};
const cacheBtn = () => {
  info.value.salesNumber = 0;
  detailList.value = detailList.value.filter((item) => item.display);
  const loadinginstarnface = ElLoading.service({
    background: 'rgba(223, 223, 223, .7)'
  });
  info.value.coverList = coverList.value.map((item) => item.response.url);
  info.value.detail = detailList.value.map((item) => ({
    display: item.display,
    value: item.value,
  }));
  postProduct(info.value)
    .then((res) => {
      if (res.code === 200) {
        ElMessage.success("添加成功");
        init();
      } else {
        ElMessage.error("添加失败");
      }
      loadinginstarnface.close();
    }).catch(() => {
      ElMessage.error("服务错误");
      loadinginstarnface.close();
    });
}
const init = () => {
  info.value = {
    name: "",
    theme: "",
    type1: "toner",
    type2: "",
    brand: "",
    typeName: "",
    coverList: [],
    detail: [],
  };
  detailList.value = [
    {
      sort: 1,
      display: "Feature",
      value: "Compatible",
      required: false,
    },
    {
      sort: 2,
      display: "Quality",
      value: "Grade A+",
      required: false,
    },
    {
      sort: 3,
      display: "For Use In",
      value: "",
      required: false,
    },
    {
      sort: 4,
      display: "Toner Load",
      value: "",
      required: false,
    },
    {
      sort: 6,
      display: "Page Yields",
      value: "",
    },
    {
      sort: 7,
      display: "Toner Powder",
      value: " lmported Toner Powder",
      required: false,
    },
    {
      sort: 8,
      display: "MOQ",
      value: "1 PC",
      required: false,
    },
    {
      sort: 9,
      display: "Packing",
      value: "Neutral Packing / Customized Packing",
      required: false,
    },

    {
      sort: 10,
      display: "Place Of Origin",
      value: "Guangdong, China",
      required: false,
    },
  ];
  detailList.value = detailList.value.sort((a, b) => a.sort - b.sort);
  coverList.value = [];
};
const handlePrview = (uploadFile) => {
  console.log('kadjhsk');
  
  dialogImageUrl.value = uploadFile.url
  dialogVisible.value = true
}
onMounted(() => {
  init();
});
</script>
<style scoped>
.header-btn {
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    padding: 0 20px;
    right: 40px;
    background-color: #fff;
    bottom: 10px;
    box-shadow: 0 0 2px var(--theme-color);
}
.text-info,
.image-info {
  min-height: 200px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #aaa;
  border-radius: 10px;
}
.title {
  line-height: 48px;
}
:deep(.operate .cell) {
  display: flex;
  justify-content: space-around;
}
.sort {
  height: 32px;
  width: 30px;
  border-radius: 5px;
  font-size: 20px;
  background-color: var(--theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.disabled {
  opacity: 0.7;
  cursor: no-drop;
  pointer-events: none;
}
.dialog-image {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center
}
.dialog-image img {
  height: 400px;
}
</style>
