<template>
    <div class="main" ref="chartDom"></div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import * as echarts from 'echarts';

const chartDom = ref(null);
const myChart = ref(null);
const option = {
    xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'bar'
        }
    ]
};
onMounted(() => {
    myChart.value = echarts.init(chartDom.value);
    option && myChart.value.setOption(option);
})
</script>

<style  scoped>
.main {
    width: 400px;
    height: 300px;
}
</style>