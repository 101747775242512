<template>
  <div class="clients-list">
    <div class="header-filter">
      <el-input
        placeholder="请输入"
        style="width: 200px"
      ></el-input>
      <el-button type="primary" style="margin-left: 15px;" @click="handleSearch">查询</el-button>
      <el-button type="primary" style="margin-left: 15px;" @click="dialogVisibleIP = true">IP管理</el-button>
    </div>
    <div class="center-main">
      <el-auto-resizer>
        <template #default="{ height }">
          <el-table
            :data="clientsData"
            style="width: 100%;"
            :height="height"
            header-row-class-name="clients-table-header"
          >
            <el-table-column
              prop="createdAt"
              label="访问时间"
              show-overflow-tooltip
            >
                <template #default={row}>
                    {{ dayjs(row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
                </template>
            </el-table-column>
            <el-table-column
              prop="ip"
              label="IP"
              show-overflow-tooltip
            />
            <el-table-column
              prop="map"
              label="地址"
              show-overflow-tooltip
            />
            <el-table-column
              prop="website"
              label="站点"
              show-overflow-tooltip
            />
            <el-table-column
              prop="operator"
              label="运营商"
              show-overflow-tooltip
            />
          </el-table>
        </template>
      </el-auto-resizer>
      <div class="footer-pager">
        <el-pagination
          v-model="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 30]"
          layout="sizes, prev, pager, next, jumper, ->, total"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
     <el-dialog
        v-model="dialogVisibleIP"
        title="IP管理"
        width="600"
     >
      <el-table
        :data="callerIPData"
        height="400"
      >
        <el-table-column prop="ip" label="IP" />
        <el-table-column prop="createdAt" label="创建时间" >
          <template #default={row}>
            {{ dayjs(row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column prop="op" label="操作" width="100">
          <template #default="{row}">
            <el-button type="danger" @click="handleDeleteIp(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="add-ip">
        <p>添加新的忽略IP</p>
        <el-input v-model="currentIp" style="width: 180px;"></el-input>
        <el-button type="primary" @click="handleAddCallerIp">添加</el-button>
      </div>
     </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {
  ElTable,
  ElTableColumn,
  ElMessage,
  ElInput,
  ElPagination,
  ElAutoResizer,
  ElButton, 
  dayjs,
  ElDialog
} from "element-plus";
import { getCallerList } from "../../api/caller";
import { getCallerip, deleteCallerip, postCallerip } from "@/api/callerip";
const currentPage = ref(1);
const pageSize = ref(10);
const total = ref(50);
const clientsData = ref([]);  
const getClientsData = () => {
  getCallerList({
    '$sort[createdAt]': -1,
    $limit: pageSize.value,
    $skip: (currentPage.value - 1) * pageSize.value,
  })
    .then((res) => {
      clientsData.value = res.data;
      total.value = res.total;
    })
    .catch((err) => {
      ElMessage.error("客户信息获取失败");
      console.log(err);
    });
};
const handleSizeChange = (value) => {
  pageSize.value = value;
  getClientsData();
};
const handleCurrentChange = (value) => {
  currentPage.value = value;
  getClientsData();
};
const handleSearch = () => {
    getClientsData();
}
const dialogVisibleIP = ref(false);
const callerIPData = ref([]);
const getCallerIpData = () => {
  getCallerip({}).then(res => {
    callerIPData.value = res.data;
    console.log(res);
  })
}
const handleDeleteIp = (id) => {
  deleteCallerip(id).then(res => {
    if (res.code != 200) return ElMessage.error('删除失败')
    ElMessage.success('删除成功');
  getCallerIpData();
  }).catch(() => ElMessage.error('删除失败'));
}
const currentIp = ref('');
const handleAddCallerIp = () => {
  if (/([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])(\.([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])){3}$/.test(currentIp.value)) {
    postCallerip({ip: currentIp.value}).then(res => {
      if (res.code != 200) return  ElMessage.error('添加失败');
      ElMessage.success('添加成功');
      currentIp.value = '';
      getCallerIpData();
    })
  } else {
    ElMessage.warning('IP格式有问题');
  }
}
onMounted(() => {
  getCallerIpData();
  getClientsData();
  document.onresize = () => {
    console.log(this);
  };
});
</script>
<style scoped>
.clients-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header-filter,
.footer-pager {
  height: 60px;
  display: flex;
  align-items: center;
}
.footer-pager {
  justify-content: center;
}
.center-main {
  flex: 1;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(222, 222, 222, 0.8);
  display: flex;
  flex-direction: column;
}
.el-table {
  flex: 1;
}
.add-ip {
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.el-pagination {
  height: 60px;
}
/* :deep(.el-popper) {
  max-width: 260px;
} */
:deep(.clients-table-header) {
  color: #000;
  font-weight: 600;
  border-bottom: 2px solid var(--theme-color);
}
</style>
